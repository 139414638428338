import React, { FormEvent, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styles from './AddNewProject.module.scss';
import ProjectEditForm, { ActionPanel } from './Forms/ProjectEditForm';
import { EventFields } from './Forms/ProjectEventEditFields';
import SalesforceTable from './Salesforce';
import SubProjectsGrid from './SubProjectsGrid';
import EventTable from './Events';
import TimeTracking from './TimeTracking';
import { transformer, useProjectData } from './helpers';
import { N3AEntitlements, Routes } from '../../constants';
import { history } from '../../store';
import {
  POST_PROJECTS,
  PUT_PROJECTS,
  deleteProject,
  COPY_PROJECT,
  DELETE_STAFF_FROM_PROJECT,
  ADD_STAFF_TO_PROJECT,
} from '../../actions/projects';
import { IProjectHeader, ICompleteEeventDetails } from '../../utils/api.d';
import cx from 'classnames';
import { CompleteEventModal } from './CompleteEventModal';
import {
  projectType as ProjectTypeEnum,
  fulfillmentStatus as FulfillmentStatusEnum,
  staffRoles as staffRolesEnum,
  projectType as projectTypeEnum,
} from '../../enums';
import { createUTCDate } from '../../utils/helpers';
import { FULFILL } from '../../actions/events';
import { getDSAReduxState } from '../../reducers/dsas';
import { getProject } from '../../reducers/project';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getSalesforce } from '../../reducers/salesforce';
import { getCompleteEventInfo } from '../../reducers/completeEvent';
import AjaxSpinner from '../../components/AjaxSpinner';
import {
  MiscSubProject,
  showEventDetailsForProjectConfig,
  showProjectPickerConfig,
  completeEventButtonText,
  showSubProjectSectionForProjectConfig,
  showTimeTrackingForProjectConfig,
  showSalesForceSectionForProjectConfig,
  showStaffGridForProjectConfig,
  redundantProjectsConfig,
  canAddEventsToProjectConfig,
} from './constants';
import { standardDateFormat } from '../../utils/helpers';
import { getUser } from '../../reducers/user';
import CopyProjectModal from './CopyProjectModal';
import StaffGrid from './Staff';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

interface AddressBarProps {
  type: string;
  id?: string;
  parentId?: string;
  backUrl?: string;
  dsaId: string;
}

export default function AddNewProject() {
  const { type, id, parentId, backUrl, dsaId } = useParams<AddressBarProps>();
  const location = useLocation();
  const [isCompleteEventModalOpen, setCompleteEventModalOpen] = useState(false);
  const [isCopyProjectModalOpen, setCopyProjectModalOpen] = useState(false);
  const { currentDSAById } = useSelector(getDSAReduxState, shallowEqual);
  const { data, parentProjectInfo, isLoading: isHeaderDataLoading } = useSelector(getProject, shallowEqual);
  const projectType = Object.values(ProjectTypeEnum).find(value => value.id.toString() === type);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const platformPlan = Number(type) === ProjectTypeEnum.Plan.id;
  const purchasedProducts = useSelector(getSalesforce, shallowEqual);
  const completeEventInfo = useSelector(getCompleteEventInfo, shallowEqual);
  const { emailAddress, entitlements } = useSelector(getUser, shallowEqual) || {};
  const virtualEventSubProjectId = projectTypeEnum.VirtualEventSubProject.id.toString();

  const toggleConfirm = () => {
    setConfirmOpen(!isConfirmOpen);
  };

  const {
    dispatch,
    isLoading,
    staffs,
    selectedProjectData,
    eventsList,
    isEventsListLoading,
    primaryEventDetails,
    isPrimaryEventLoading,
  } = useProjectData(id);

  //  If we have a project id, use the data from the header endpoint for header data.
  //  If not, if we have a dsaid use the id and name from the dsa endpoint
  //  If we have neither, set to an empty object and let the header component manage.
  const getHeaderData = () => {
    // TODO: refactor this method
    let finalHeaderData;

    if (id && data) {
      finalHeaderData = data;
    }

    if (!id && dsaId && currentDSAById) {
      const { id, accountName, accountLongId } = currentDSAById;

      finalHeaderData = {
        dsa: {
          id: id,
          name: accountName,
          accountLongId,
        },
        projectType,
      } as Partial<IProjectHeader>;
    }

    if (!id && parentId && parentId !== 'parent' && type === virtualEventSubProjectId && parentProjectInfo) {
      const { projectAccounts } = parentProjectInfo;

      if (finalHeaderData) {
        finalHeaderData.projectAccounts = projectAccounts;
      } else {
        finalHeaderData = { projectAccounts };
      }
    }

    return finalHeaderData || {};
  };

  const headerData = getHeaderData();

  const returnToPreviousScreen = () => {
    let redirectUrl = Routes.DSADetail.replace(':dsaid', dsaId);
    if (backUrl && backUrl !== ':backUrl') {
      redirectUrl = decodeURIComponent(backUrl);
    }

    history.push(redirectUrl);
  };

  const deleteCurrentProject = () => {
    const { id, dsa } = headerData;
    const dsaId = dsa && dsa.id;

    id && dispatch(deleteProject({ id, dsaId: dsaId }));
  };

  const onDeleteProjectConfirm = () => {
    toggleConfirm();
    deleteCurrentProject();
  };

  const onDeleteProject = () => {
    toggleConfirm();
  };

  const goToAuditLog = () => {
    const { id } = headerData;
    history.push(
      Routes.ProjectAuditLog.replace(':id', (id || 0).toString()).replace(
        ':backUrl',
        encodeURIComponent(location.pathname),
      ),
    );
  };

  const isNewProject = location.pathname.indexOf('new-edit-project') === -1;

  const copyProjectModalToggle = () => setCopyProjectModalOpen(!isCopyProjectModalOpen);
  const completeEventModalToggle = () => setCompleteEventModalOpen(!isCompleteEventModalOpen);

  const projectTypeId = (headerData.projectType && headerData.projectType.id) || Number(type) || 0;
  const isAdmin = entitlements && entitlements.includes(N3AEntitlements.Admin);
  const canCopyProject = Boolean(headerData.id) && isAdmin && projectTypeId === ProjectTypeEnum.WebcastSubProject.id;
  
  useEffect(() => {
    const originalWarn = console.warn;
    console.warn = (...args: [any?, ...any[]]) => {
      if (args[0]?.includes('DOMNodeInserted')) return;
      originalWarn.apply(console, args as [any?, ...any[]]);
    };
  
    return () => {
      console.warn = originalWarn;
    };
  }, []);
  
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'align': [] }],
      ['clean']
    ],
    history: {
      delay: 500,
      userOnly: true
    }
  };
  
  const formats = [
    'header',
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'align',
    'indent'
  ];

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    const notesContent = (document.querySelector('.ql-editor') as HTMLDivElement).innerHTML;
    const actionType = isNewProject ? POST_PROJECTS : PUT_PROJECTS;
    const payload = transformer(event, parentId, id, type, dsaId, notesContent);
    dispatch({ type: actionType, payload });
  };

  const fulfillEvent = (completeEventData: ICompleteEeventDetails) => {
    const type = FULFILL;
    const payload = {
      completeEventDate: createUTCDate(completeEventData.eventDate),
      completeEventEndDate: createUTCDate(completeEventData.eventEndDate),
      completeEventTitle: completeEventData.eventTitle.trim(),
      completeEventId: completeEventData.eventId.trim(),
      projectId: headerData.id,
    };

    dispatch({ type, payload });
    completeEventModalToggle();
  };

  const firstPLIInList = purchasedProducts && purchasedProducts.list && purchasedProducts.list[0];
  const isPurchasedProductsLoading = purchasedProducts && purchasedProducts.isLoading;

  const completeEventData = {
    eventTitle:
      (primaryEventDetails && primaryEventDetails.name) ||
      (!primaryEventDetails && firstPLIInList && firstPLIInList.orderProductEventTitle) ||
      '',
    eventDate: (headerData && headerData.eventStart) || '',
    eventEndDate: (headerData && headerData.eventEnd) || '',
    eventId:
      (primaryEventDetails && primaryEventDetails.eventId && primaryEventDetails.eventId.toString()) ||
      (!primaryEventDetails &&
        firstPLIInList &&
        firstPLIInList.orderProductEventId &&
        firstPLIInList.orderProductEventId.toString()) ||
      '',
  };

  const canDeleteProject = Boolean(
    isAdmin &&
      headerData &&
      !headerData.hasChildren &&
      headerData.fulfillmentStatus &&
      headerData.fulfillmentStatus.id.toString() === FulfillmentStatusEnum.PendingFulfillment.id.toString(),
  );

  const isProjectFulfilled = Boolean(
    headerData &&
      headerData.fulfillmentStatus &&
      headerData.fulfillmentStatus.value === FulfillmentStatusEnum.Fulfilled.value,
  );
  const isCompleteEventDisabled =
    isProjectFulfilled || Boolean(isPrimaryEventLoading) || Boolean(isPurchasedProductsLoading);

  const isCompleteEventVisible =
    Boolean(headerData.id) &&
    (ProjectTypeEnum.VirtualEvent.id === projectTypeId ||
      ProjectTypeEnum.WebcastSubProject.id === projectTypeId ||
      ProjectTypeEnum.Misc.id === projectTypeId);
  const isRedundantProjectType = redundantProjectsConfig.includes(projectTypeId);
  const isSubProjectSectionVisible = showSubProjectSectionForProjectConfig.includes(projectTypeId);
  const isTimeTrackingVisible = showTimeTrackingForProjectConfig.includes(projectTypeId);
  const isSalesForceSectionVisible = showSalesForceSectionForProjectConfig.includes(projectTypeId);
  const areEventDetailsFieldsVisible = showEventDetailsForProjectConfig.includes(projectTypeId);
  const isAddProjectBtnVisible = Boolean(headerData.id && !isRedundantProjectType);
  const isAddEventsBtnVisible = canAddEventsToProjectConfig[projectTypeId];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let roles: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let staffNames: any[] = [];
  const isStaffSectionVisible = Boolean(headerData.id) && showStaffGridForProjectConfig.includes(projectTypeId);

  // TODO: temp solution. We don't have Misc Sub-project type. Remove after implementation
  const idOfProjectType =
    projectTypeId &&
    +projectTypeId === ProjectTypeEnum.Misc.id &&
    ((headerData && headerData.parentProject) || (parentId && parentId !== 'parent'))
      ? MiscSubProject
      : projectTypeId;

  const showProjectPicker = showProjectPickerConfig[idOfProjectType];
  let isProjectPickerDisabled = false;

  if (showProjectPicker) {
    const now = new Date(new Date().setUTCHours(0, 0, 0, 0));
    const start =
      headerData.fulfillmentStart || (headerData.parentProject && headerData.parentProject.fulfillmentStart);
    const end = headerData.fulfillmentEnd || (headerData.parentProject && headerData.parentProject.fulfillmentEnd);

    if (start && end) {
      isProjectPickerDisabled = new Date(start) > now || now > new Date(end);
    }
  }

  const completeEventButtonConfig = {
    isVisible: isCompleteEventVisible,
    isDisabled: isCompleteEventDisabled,
    text: isCompleteEventDisabled ? completeEventButtonText.fulfilled : completeEventButtonText.complete,
    completeEvent: completeEventModalToggle,
  };
  const copyProjectButtonConfig = {
    copyProject: copyProjectModalToggle,
    canCopyProject,
  };

  const createdUpdatedInfo =
    isLoading || isNewProject ? null : (
      <div className={styles.section}>
        <div>
          <span className={styles.createdUpdatedInfoLabel}>Created: </span>
          {headerData.createdBy}
          {headerData.createdBy ? ', ' : ''}
          {standardDateFormat(headerData.created)}
        </div>
        <div>
          <span className={styles.createdUpdatedInfoLabel}>Last Edited: </span>
          {headerData.lastUpdatedBy}
          {headerData.lastUpdatedBy ? ', ' : ''}
          {standardDateFormat(headerData.lastUpdated)}
        </div>
        <div>
          <span className={styles.createdUpdatedInfoLabel}>
            <a href="#" onClick={goToAuditLog}>
              Audit Log
            </a>
          </span>
        </div>
      </div>
    );
  const projectForm =
    isLoading || isHeaderDataLoading || staffs.isLoading ? null : (
      <ProjectEditForm
        formDataInit={headerData || {}}
        staffs={staffs}
        projectType={type}
        onSubmit={onSubmit}
        projectPlan={platformPlan}
        showProjectPicker={showProjectPicker}
        isProjectPickerDisabled={isProjectPickerDisabled}
        isProjectManagerDisabled={isStaffSectionVisible}
      />
    );

  const prepareDataForStaffDropdown = () => {
    return staffs.briefInfoList.filter(staff => staff.isActive).map(staff => `${staff.firstName} ${staff.lastName}`);
  };

  const prepareDataForStaffRolesDropdown = () => {
    return Object.keys(staffRolesEnum).map(roleType => {
      return staffRolesEnum[roleType].name;
    });
  };

  if (isStaffSectionVisible) {
    roles = prepareDataForStaffRolesDropdown();
    staffNames = prepareDataForStaffDropdown();
  }

  const removeStaffFromProject = (staffData: { id: number | string }) => {
    const type = DELETE_STAFF_FROM_PROJECT;
    const payload = {
      projectId: headerData.id,
      projectTypeId: headerData.projectType && headerData.projectType.id,
      dsaCustomerId: headerData.dsa && headerData.dsa.id,
      id: staffData.id,
    };

    dispatch({ type, payload });
  };

  const addStaffToProject = (staffData: { roleName: string; staffName: string }) => {
    const { roleName, staffName } = staffData;
    const staffInfo = staffs.briefInfoList.find(staff => `${staff.firstName} ${staff.lastName}` === staffName);
    const staffRoleKey = Object.keys(staffRolesEnum).find(key => staffRolesEnum[key].name === roleName);
    const roleType = staffRoleKey && staffRolesEnum[staffRoleKey].roleType;
    const type = ADD_STAFF_TO_PROJECT;
    const payload = {
      staffId: staffInfo && staffInfo.id,
      staffRole: roleType,
      projectId: headerData.id,
      projectTypeId: headerData.projectType && headerData.projectType.id,
      dsaCustomerId: headerData.dsa && headerData.dsa.id,
    };

    dispatch({ type, payload });
  };

  const addSubProject = (projectType: number) => {
    const parentProjectId = headerData && headerData.id ? headerData.id.toString() : 'parent';
    const url = Routes.AddNewProject.replace(':parentId', parentProjectId)
      .replace(':type', projectType.toString())
      .replace(':dsaId', dsaId);
    history.push(url);
  };

  const proceedCopyProject = (numberOfCopies: number) => {
    const payload = { projectId: headerData.id, numberOfCopies };

    dispatch({ type: COPY_PROJECT, payload });
    copyProjectModalToggle();
  };

  const assignedStaff = headerData.projectStaff
    ? headerData.projectStaff.map(projectStaff => {
        const currentStaff = staffs.briefInfoList.find(x => x.id === projectStaff.staff.id);
        projectStaff['canDeleteRecord'] = isAdmin || (currentStaff ? currentStaff.email === emailAddress : false);
        return projectStaff;
      })
    : [];

  const showSpinner =
    isEventsListLoading || isPrimaryEventLoading || (completeEventInfo && completeEventInfo.isLoading);

  return (
    <div id="NewProjectPage" className={styles.root}>
      {showSpinner && <AjaxSpinner />}
      <div className={styles.rightPane}>
        <ActionPanel
          onClose={returnToPreviousScreen}
          onDelete={onDeleteProject}
          completeEventButtonConfig={completeEventButtonConfig}
          copyProjectButtonConfig={copyProjectButtonConfig}
          canDeleteProject={canDeleteProject}
        />
        <div className={styles.section}>{projectForm}</div>
        {createdUpdatedInfo}
        <div className={styles.notesContainer}>
          <label className={cx(styles.notesLabel)}>Notes</label>
          <ReactQuill
            id={'notes-editor'}
            value={headerData.notes || ''}
            theme="snow"
            modules={modules}
            formats={formats}
          />
        </div>

        {isStaffSectionVisible && !isHeaderDataLoading && !staffs.isLoading && (
          <div className={styles.section}>
            <StaffGrid
              assignedStaff={assignedStaff}
              isAdmin={isAdmin}
              staffList={staffNames}
              roles={roles}
              emailAddress={emailAddress}
              removeStaff={removeStaffFromProject}
              saveStaff={addStaffToProject}
            />
          </div>
        )}

        {isSubProjectSectionVisible && !isHeaderDataLoading && (
          <div className={styles.section}>
            <SubProjectsGrid
              projectId={id}
              projectType={projectTypeId.toString()}
              isAddProjectBtnVisible={isAddProjectBtnVisible}
              addSubProject={addSubProject}
              dsaId={dsaId}
              emailAddress={emailAddress}
            />
          </div>
        )}
        {!isHeaderDataLoading && (
          <div className={styles.eventTable}>
            <EventTable
              formDataInit={selectedProjectData}
              eventHeaderData={headerData ? headerData : {}}
              projectType={type}
              isNewProject={isNewProject}
              emailAddress={emailAddress}
              isProjectFulfilled={isProjectFulfilled}
              isAddEventsBtnVisible={isAddEventsBtnVisible}
              isAdmin={isAdmin}
            >
              <EventFields
                eventHeaderData={headerData ? headerData : {}}
                formDataInit={selectedProjectData}
                projectType={type}
                events={eventsList}
                showEventDetailsFields={areEventDetailsFieldsVisible}
              />
            </EventTable>
          </div>
        )}
        {selectedProjectData && headerData && headerData.id && isTimeTrackingVisible && (
          <div className={styles.section}>
            <TimeTracking emailAddress={emailAddress} isAdmin={isAdmin} projectId={headerData.id.toString()} />
          </div>
        )}
        {isSalesForceSectionVisible && !isHeaderDataLoading && (
          <SalesforceTable
            projectName={(selectedProjectData && selectedProjectData.name) || ''}
            hidePlanProject={!platformPlan}
            projectId={id}
            accountLongId={headerData.dsa && headerData.dsa.accountLongId}
            isProjectFulfilled={isProjectFulfilled}
            emailAddress={emailAddress}
          />
        )}
      </div>
      {isPurchasedProductsLoading || isPrimaryEventLoading ? null : (
        <CompleteEventModal
          isOpen={isCompleteEventModalOpen}
          completeEventData={completeEventData}
          completeEvent={fulfillEvent}
          cancelCompleteEvent={completeEventModalToggle}
        />
      )}
      {canCopyProject && (
        <CopyProjectModal
          isOpen={isCopyProjectModalOpen}
          proceedCopyProject={proceedCopyProject}
          cancelCopyProject={copyProjectModalToggle}
        />
      )}
      <Modal className={styles.modal} isOpen={isConfirmOpen} toggle={toggleConfirm}>
        <ModalHeader toggle={toggleConfirm}>Are you sure?</ModalHeader>
        <ModalBody>Are you sure you want to delete this project?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleConfirm}>
            Cancel
          </Button>
          <Button color="danger" onClick={onDeleteProjectConfirm}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
